import { environment } from 'src/environments/environment';

export class Apis {
  static me = `${environment.base_url}/admin/me`;
  static adminLogin = `${environment.base_url}/admin/login/email`;

  static merchantMe = `${environment.base_url}/merchant/me`;
  static merchantSignUp = `${environment.base_url}/merchant/signUp`;
  static merchantLogin = `${environment.base_url}/merchant/login/byEmail`;
  static addAddressFieldSchemaValuesForMerchant = `${environment.base_url}/merchant/addAddressFieldSchemaValues`;
  static addBusinessIdentifierFieldSchemaValues = `${environment.base_url}/merchant/addBusinessIdentifierFieldSchemaValues`;
  static updateOperatingCurrency = `${environment.base_url}/merchant/updateOperatingCurrency`;
  static updateCurrencyPriority = `${environment.base_url}/merchant/updateCurrencyPriority`;
  static updateTaxInfo = `${environment.base_url}/merchant/updateTaxInfo`;
  static updateBusinessContact = `${environment.base_url}/merchant/updateBusinessContact`;
  static updateDevContact = `${environment.base_url}/merchant/updateDevContact`;
  static updateStakeHolder = `${environment.base_url}/merchant/updateStakeHolder`;
  static attachDocumentToMerchant = `${environment.base_url}/merchantProfile/attachDocument`;
  static attachDocumentToStakeHolder = `${environment.base_url}/merchantProfile/attachDocumentToStakeHolder`;
  static addSettlementAccount = `${environment.base_url}/merchant/addSettlementAccount`;
  static updateColorCodeHex = `${environment.base_url}/merchant/updateColorCode`;
  static updateLogo = `${environment.base_url}/merchant/attachLogoDocumentToMerchant`;

  static getTransactionState = `${environment.base_url}/enums/transactionState`;
  static updateWebHooks = `${environment.base_url}/merchant/updateWebHook`;
  static updateTransactionAmount = `${environment.base_url}/transaction/refund`;
  static refreshWebHooksStatus = `${environment.base_url}/webhooks/refreshWebhookStatus`;

  static getDownloadTransactions = `${environment.base_url}/transaction/exportTransactionCsv`;
  static exportTransactionCSVForRefundedTransaction = `${environment.base_url}/transaction/exportTransactionCsvForRefundedTransactions`;

  static getWebhookList = `${environment.base_url}/webhooks/list`;
  static getWebhookCount = `${environment.base_url}/webhooks/count`;

  static accessControl = `${environment.base_url}/accessControl`;
  static byCategory = `${environment.base_url}/accessControl/byCategory`;
  static accessControlUpdate = `${environment.base_url}/admin/accessControlCategory/updateOrder`;
  static allAccessControlCategory = `${environment.base_url}/admin/accessControlCategory/all`;

  static refreshToken = `${environment.base_url}/refreshToken`;

  static otpDeliveryChannel = `${environment.base_url}/enums/otpDeliveryChannel`;
  static initTwoFactor = `${environment.base_url}/twoFactor/init`;
  static validateTwoFactor = `${environment.base_url}/twoFactor/validateTwoFactorOTP`;
  static twoFactorTOTPSetUp = `${environment.base_url}/twoFactor/isTOTPSetUpDone`;
  static initialSetupTwoFactor = `${environment.base_url}/twoFactor/initiateSetupTwoFactor`;
  static setupTwoFactor = `${environment.base_url}/twoFactor/setUpTOtp`;
  static otpDigits = `${environment.base_url}/otp/getOtpDigitsCount`;

  static sendOTP = `${environment.base_url}/password/forgotPassword/sendOtp`;
  static forgotPassword = `${environment.base_url}/password/forgotPassword`;
  static changePassword = `${environment.base_url}/password/changePassword`;
  static passwordConditions = `${environment.base_url}/password/conditions`;

  static getMobileVerificationOtp = `${environment.base_url}/verify/mobileNumber/init`;
  static getEmailVerificationOtp = `${environment.base_url}/verify/emailId/init`;
  static mobileVerify = `${environment.base_url}/verify/mobileNumber`;
  static emailVerify = `${environment.base_url}/verify/emailId`;

  static sendMail = `${environment.base_url}/trustedDevice/sendVerificationMailAgain`;
  static getResendOtpDelaySeconds = `${environment.base_url}/otp/getResendOtpDelaySeconds`;

  static getAllAdmin = `${environment.base_url}/admin/allAdmin`;
  static getAccessRole = `${environment.base_url}/admin/accessRole`;
  static createNewAdmin = `${environment.base_url}/admin/createAdmin`;
  static addAccessRole = `${environment.base_url}/admin/accessRole/add`;
  static addAccessControlPolicy = `${environment.base_url}/admin/accessControlPolicy/add`;
  static deleteAccessControlPolicy = `${environment.base_url}/admin/accessControlPolicy/delete`;
  static getAccessControlByAccessRoleId = `${environment.base_url}/accessControl/forAccessRole`;
  static getAccessRoleByAccessRoleId = `${environment.base_url}/admin/accessRole/byId`;
  static getEntityNames = `${environment.base_url}/admin/entity`;
  static getClassStructureForEntity = `${environment.base_url}/admin/entity/getClassStructure`;
  static updateColumnsForAccessRole = `${environment.base_url}/admin/accessRole/updateFilterColumns`;

  static orgStructure = `${environment.base_url}/admin/orgStructure`;
  static detachAdminFromOrgStructure = `${environment.base_url}/admin/orgStructure/detachAdmin`;

  static countryList = `${environment.base_url}/countries`;
  static documentExpiryActionList = `${environment.base_url}/enums/documentExpiryAction`;
  static notificationReadStatusType = `${environment.base_url}/enums/notificationReadStatusType`;
  static getOTPSenderImplementation = `${environment.base_url}/otpSenderImpl/getOtpSender`;
  static addDocumentCategory = `${environment.base_url}/admin/documentCategory/addDocumentCategory`;
  static addStakeHolderDocumentCategory = `${environment.base_url}/admin/documentCategory/addStakeHolderDocumentCategory`;
  static updateStakeHolders = `${environment.base_url}/adminCountries/updateStakeHoldersIdText`;
  static updateCountryService = `${environment.base_url}/adminCountries/updateCountry`;
  static addOtpSender = `${environment.base_url}/adminCountries/addOtpSenderImpl`;
  static addCountryAddressSchema = `${environment.base_url}/adminCountries/addressSchema`;
  static addBusinessIdentifierSchema = `${environment.base_url}/adminCountries/businessIdentifierSchema`;
  static addIndividualIdentifierSchema = `${environment.base_url}/adminCountries/individualIdentifierSchema`;
  static getAllTheCurrencies = `${environment.base_url}/currency/all`;

  static apiAccessLog = `${environment.base_url}/admin/accessLog/api`;
  static userType = `${environment.base_url}/enums/userType`;
  static apiTires = `${environment.base_url}/enums/apiTire`;
  static getApiForAccessLogs = `${environment.base_url}/admin/accessLog/getAllApi`;
  static dateRangeType = `${environment.base_url}/enums/dateRangeType`;
  static getHttpMethod = `${environment.base_url}/enums/httpMethod`;
  static dataBaseAccessLogs = `${environment.base_url}/admin/accessLog/dataBase`;
  static getTableName = `${environment.base_url}/admin/accessLog/getTableNames`;
  static getClientDeviceByUserId = `${environment.base_url}/clientDevice/getByUserId`;

  static getAllForexFeeders = `${environment.base_url}/forexFeeder`;
  static implNames = `${environment.base_url}/forexFeeder/implNames`;
  static forexFeederRunIntervalUnit = `${environment.base_url}/enums/forexFeederRunIntervalUnit`;
  static getForexFeederInsertPolicy = `${environment.base_url}/enums/forexFeederInsertPolicy`;
  static deactivate = `${environment.base_url}/forexFeeder/deactivate`;
  static getAllForexMarginPolicy = `${environment.base_url}/enums/forexMarginPolicy`;
  static getAllForexFeederConfigs = `${environment.base_url}/forexFeederConfig`;
  static approveForex = `${environment.base_url}/forex/approve`;
  static rejectForex = `${environment.base_url}/forex/reject`;
  static deActivateForexFeederConfig = `${environment.base_url}/forexFeederConfig/deactivate`;
  static getAllForexes = `${environment.base_url}/forex`;
  static getAllForexesForApproval = `${environment.base_url}/forex/getAllForexForApproval`;
  static addForexesRate = `${environment.base_url}/forex`;
  static forexRateInsertedVia = `${environment.base_url}/enums/ForexRateInsertedVia`;
  static getDataApprovalStatus = `${environment.base_url}/enums/dataApprovalStatus`;
  static getForexDataExpiryType = `${environment.base_url}/enums/forexDataExpiryType`;
  static getForexFeederConfigById = `${environment.base_url}/forexFeederConfig/byId`;
  static updateForexFeederConfig = `${environment.base_url}/forexFeederConfig/updateForexFeederConfig`;
  static getForexForCurrency = `${environment.base_url}/forex/selectForPair`;

  static checkIfOTPRequiredForImpersonation = `${environment.base_url}/impersonation/checkIfOTPRequiredForImpersonation`;
  static otpForImpersonation = `${environment.base_url}/impersonation/otpForImpersonation`;
  static impersonateLogin = `${environment.base_url}/admin/login/impersonate`;

  static getAdminPaymentProvider = `${environment.base_url}/admin/paymentProvider`;
  static getAdminPaymentInstrumentType = `${environment.base_url}/enums/paymentInstrumentType`;
  static getAdminPaymentSubInstrumentType = `${environment.base_url}/enums/paymentInstrumentSubType`;

  static getDataStatus = `${environment.base_url}/enums/dataStatus`;

  static createAdminPaymentProvider = `${environment.base_url}/admin/paymentProvider/add`;
  static createAdminPaymentProviderCurrency = `${environment.base_url}/admin/paymentProviderCurrencyConfig/add`;
  static createAdminPaymentProviderInstrument = `${environment.base_url}/admin/paymentProviderInstrumentConfig/add`;
  static createAdminPaymentProviderSubInstrument = `${environment.base_url}/admin/paymentProviderSubInstrumentConfig/add`;
  static getPaymentProviderInstrumentImplName = `${environment.base_url}/admin/paymentProvider/instrumentImplName`;
  static documentUploadByUrl = `${environment.base_url}/document/documentUploadUrl`;
  static attachDocumentToPaymentProvider = `${environment.base_url}/admin/paymentProvider/attachDocument`;
  static getPublicUrlByDocumentId = `${environment.base_url}/document/getPublicUrl`;
  static getMiscPricingParamType = `${environment.base_url}/enums/miscPricingParamType`;
  static getMiscPricingParamTypeValue = `${environment.base_url}/enums/miscPricingParamTypeValue`;
  static updatePaymentProviderRateToPaymentInstrument = `${environment.base_url}/admin/paymentProviderSubInstrumentConfig/paymentInstrument/updatePaymentProviderRateToPaymentInstrument`;
  static attachDocumentToTransaction = `${environment.base_url}/transaction/attachDocumentToTransaction`;
  static changeActivationStatus = `${environment.base_url}/admin/paymentProvider/changeActivationStatus`;
  static updatePaymentProviderPriorityToPaymentInstrument = `${environment.base_url}/admin/paymentProviderSubInstrumentConfig/paymentInstrument/updatePaymentProviderPriorityToPaymentInstrument`;

  static getFieldType = `${environment.base_url}/enums/fieldType`;
  static getFieldSchemaFieldSelectOptionType = `${environment.base_url}/enums/fieldSchemaFieldSelectOptionType`;
  static getComplexFieldOrientation = `${environment.base_url}/enums/complexFieldOrientation`;
  static getSelectOptionFromDB = `${environment.base_url}/selectOptions`;
  static getFieldSchemaValueDetailsForSelectOption = `${environment.base_url}/fieldSchemaValue`;

  static messageTrailList = `${environment.base_url}/messageTrails/getMessageTrails`;
  static addMessage = `${environment.base_url}/messageTrails/addMessageTrail`;
  static updateReadGenericMessages = `${environment.base_url}/messageTrails/updateReadStatus`;
  static getMessageType = `${environment.base_url}/enums/messageTypes`;
  static getMessageTrailHead = `${environment.base_url}/admin/messageTrailHead`;
  static addMessageTrailHead = `${environment.base_url}/messageTrails/addMessageTrailHead`;

  static getAdminMerchantList = `${environment.base_url}/admin/merchant/getMerchantList`;
  static getMerchantById = `${environment.base_url}/admin/merchant/getMerchantById`;
  static updateMerchantProfileStatus = `${environment.base_url}/admin/merchant/updateMerchantProfileStatus`;
  static attachPaymentInstrumentForOperatingCurrency = `${environment.base_url}/admin/merchant/operatingCurrency/attachPaymentInstrumentConfig`;
  static merchantAccountStateType = `${environment.base_url}/enums/merchantAccountState`;
  static getAllTransactionCapturePreferenceType = `${environment.base_url}/enums/transactionCapturePreference`;
  static getAllTransactionCaptureFailActionType = `${environment.base_url}/enums/transactionCaptureFailAction`;
  static getWalletSettlementCycleUnit = `${environment.base_url}/enums/walletSettlementCycleUnit`;
  static addSettlementCycleSettings = `${environment.base_url}/admin/wallet/settlementCycleSettings`;
  static getRefundStrategy = `${environment.base_url}/enums/refundStrategy`;
  static getRiskLevel = `${environment.base_url}/enums/riskLevel`;
  static attachPaymentSubInstrument = `${environment.base_url}/admin/merchant/instrumentConfig/attachPaymentSubInstrumentConfig`;
  static updateOperatingCurrencyMiscPricingConfig = `${environment.base_url}/admin/merchant/operatingCurrency/updateMiscPricingConfig`;
  static updateOperatingCurrencyMerchantPaymentControlConfig = `${environment.base_url}/admin/merchant/operatingCurrency/updateMerchantPaymentControlConfig`;
  static updateOperatingCurrencyMaxMinAmount = `${environment.base_url}/admin/merchant/operatingCurrency/updateMaxAndMinAmountForOperatingCurrency`;
  static updatePaymentProviderPreference = `${environment.base_url}/admin/merchant/subInstrumentConfig/attachPaymentProviderPreference`;
  static updateRRWallet = `${environment.base_url}/admin/wallet/updateRRWallet`;
  static toggleCustomerDetails = `${environment.base_url}/admin/merchant/toggleUserDetails`;
  static enableOrDisableMerchantInstrument = `${environment.base_url}/admin/merchant/enableOrDisableMerchantInstrument`;
  static enableOrDisableMerchantSubInstrument = `${environment.base_url}/admin/merchant/enableOrDisableMerchantSubInstrument`;
  static updateRateConfigByRateConfigId = `${environment.base_url}/admin/merchant/updateRateConfig`;
  static updateMerchantPaymentControlByPaymentControlId = `${environment.base_url}/admin/merchant/updateMerchantPaymentControl`;
  static updateRefundStrategyExceptionByRefundStrategyId = `${environment.base_url}/admin/merchant/updateRefundStrategyException`;

  static allNotificationConfigList = `${environment.base_url}/notificationConfig/getAllConfigs`;
  static adminNotificationList = `${environment.base_url}/admin/notification/getAllNotification`;
  static updateNotificationConfig = `${environment.base_url}/notificationConfig/update`;
  static notificationBannerType = `${environment.base_url}/enums/notificationBannerType`;
  static notificationBannerDestination = `${environment.base_url}/enums/notificationBannerDestination`;
  static getNotification = `${environment.base_url}/notification/getNotification`;
  static getNotificationById = `${environment.base_url}/notification/getById`;
  static getBannerNotification = `${environment.base_url}/notification/getBannerNotification`;
  static markAllAsReadNotification = `${environment.base_url}/notification/markAllRead`;
  static markAsReadSpecificNotification = `${environment.base_url}/notification/markAsRead`;
  static getUnreadNotificationCountsByACGroup = `${environment.base_url}/notification/getUnreadNotificationCountsByACGroup`;
  static getClassStructure = `${environment.base_url}/admin/notification/getClassStructure`;
  static getNotificationConfigById = `${environment.base_url}/notificationConfig/getById`;
  static addNotificationManually = `${environment.base_url}/admin/notification/addNotificationManually`;
  static mayReadNotifications = `${environment.base_url}/notification/mayReadNotifications`;

  static allSystemBlockScheduleList = `${environment.base_url}/admin/systemBlockSchedule/getAll`;
  static addSystemBlockSchedule = `${environment.base_url}/admin/systemBlockSchedule/add`;
  static deactivateSystemBlockSchedule = `${environment.base_url}/admin/systemBlockSchedule/deactivate`;

  static addApiKeysForMerchant = `${environment.base_url}/merchant/createApiKey`;
  static deactivateApiKeysForMerchant = `${environment.base_url}/merchant/deActivateApiKey`;

  static linkWalletToSettlementAccount = `${environment.base_url}/merchant/wallet/linkSettlementAccountToWallet`;
  static getWallets = `${environment.base_url}/wallet`;
  static getRollingReserveWallets = `${environment.base_url}/wallet/getRRWallet`;
  static addWalletSettlement = `${environment.base_url}/merchant/addWalletSettlementConfig`;
  static merchantSettlementReactivationDuration = `${environment.base_url}/enums/mrchntStlmtAccCnfgRactvtAftTmUnt`;

  static getTransactionList = `${environment.base_url}/transaction/getTransactions`;
  static getTransactionById = `${environment.base_url}/transaction/getTransactionById`;

  static processRefundToCompleteTransaction = `${environment.base_url}/transaction/processRefundToComplete`;
  static processRefundToRejectTransaction = `${environment.base_url}/transaction/processRefundToReject`;
  static manualCaptureTransaction = `${environment.base_url}/transaction/manualCaptureTransaction`;

  static viewRefundAmountForTransaction = `${environment.base_url}/transaction/viewRefundAmountForTransaction`;
  static getRefundTransactionsForApproval = `${environment.base_url}/transaction/getRefundTransactionsForApproval`;
  static approveRefundTransaction = `${environment.base_url}/transaction/approveRefundTransaction`;
  static rejectRefundTransaction = `${environment.base_url}/transaction/rejectRefundTransaction`;

  static getAccountTransactionTable = `${environment.base_url}/accountTransactionTable`;
  static getAccountTransactionById = `${environment.base_url}/accountTransactionTable/getByAccountTransactionId`;
  static getAccountTransactionType = `${environment.base_url}/enums/accountTransactionType`;
  static getWalletsAndRRWallets = `${environment.base_url}/wallet/getWalletsAndRRWalletsList`;

  static getWalletSettlements = `${environment.base_url}/walletSettlement`;
  static getWalletSettlementStatusType = `${environment.base_url}/enums/walletSettlementStatusType`;
  static getWalletSettlementByIdOrCode = `${environment.base_url}/walletSettlement/byIdOrCode`;
  static updateWalletSettlementStatus = `${environment.base_url}/admin/walletSettlement/updateWalletSettlementStatus`;
  static updateWalletSettlementToChangeToInProgress = `${environment.base_url}/admin/walletSettlement/updateWalletSettlementStatus/inProgress`;
  static exportWalletSettlement = `${environment.base_url}/walletSettlement/exportWalletSettlementAsCsv`;
  static getExportDocumentContext = `${environment.base_url}/enums/exportDocumentContext`;
  static getColumnsToBeSelectedForPreference = `${environment.base_url}//admin/walletSettlement/getColumnsToBeSelectedForPreferenceForExportDocument`;
  static updateColumnPreferenceForExportDocument = `${environment.base_url}/admin/walletSettlement/columnPreferenceForExportDocument`;
  static deleteColumnPreferenceForExportDocument = `${environment.base_url}/admin/walletSettlement/deleteColumnPreferenceForExportDocument`;

  static getPaymentProviderCredentials = `${environment.base_url}/admin/merchant/getPaymentProviderCredentials`;
  static createPaymentProviderCredentials = `${environment.base_url}/admin/merchant/addMerchantPaymentProviderCredential`;
  static getPaymentProviderInstrument = `${environment.base_url}/admin/paymentProviderInstrument/get`;

  static createTransactionInit = `${environment.base_url}/merchant/transaction/init`;

  static updateWebhookEndPoints = `${environment.base_url}/merchant/updateWebhookEndPoints`;
  static getStateList = `${environment.base_url}/states`;
  static toggleTrustedDeviceCheck = `${environment.base_url}/admin/merchant/toggleTrustedDeviceCheck`;

  static getTransactionForGraphByState = `${environment.base_url}/transaction/getTransactionsForGraphByState`;
  static getTransactionForGraphByMerchant = `${environment.base_url}/transaction/getTransactionsForGraphByMerchant`;
  static getCountOfEachReason = `${environment.base_url}/transaction/getCountOfEachReason`;
  static getTransactionsCountByStateList = `${environment.base_url}/transaction/getTransactionsCountByState`;
}
