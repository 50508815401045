<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="assets/home/ATL-Pay-white-logo-icon.svg"
              alt=""
              height="22"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/home/logo-white-atlpay.svg" alt="" height="40" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img
              src="assets/home/ATL-Pay-white-logo-icon.svg"
              alt=""
              height="22"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/home/logo-white-atlpay.svg" alt="" height="40" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" *ngIf="notification">
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          (click)="openNotificationModal()"
        >
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill" *ngIf="totalCount">{{
            totalCount
          }}</span>
        </button>
      </div>
      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item d-flex align-items-center"
          dropdownToggle
          id="page-header-user-dropdown"
        >
          <div class="avatar-xs">
            <span class="avatar-title rounded-circle bg-primary">{{
              userInfo?.name | shortName | uppercase
            }}</span>
          </div>
          <span class="d-none d-xl-inline-block ms-1"
            >{{ userInfo?.name }}
          </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
